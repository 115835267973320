<template>
  <div class="mb-3 card">

    <div>
      <v-tabs v-model="tab" fixed-tabs>
        <v-tab key="basic">
          {{ $t('erp.lang_baseData') }}
        </v-tab>
        <v-tab v-if="isTranslationEnabled" key="translation">
          {{ $t('erp.lang_translation') }}
        </v-tab>
        <v-tab v-if="this.$store.getters['permissions/checkModule'](24)" key="pricesize">
          {{ $t('erp.langPriceSizeMatrix') }}
        </v-tab>
        <v-tab key="picture">{{ $t('erp.lang_productImage') }}</v-tab>
        <v-tab key="wareattributes">{{ $t('erp.lang_warecreate_wareattr') }}</v-tab>
        <v-tab
            key="ingredient"
            v-if="this.$store.getters['permissions/checkModule'](24)&&(id || parseInt(id) > 0) "
        >
          {{ $t("erp.lang_nav_recipe") }}
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="basic">
            <v-container fluid>
              <v-layout>
                <v-flex md4 ml-2 mr-2>
                  <v-text-field v-model="form.ware_ID"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_ware_create_id')"
                                :loading="loadingCheck"
                                :rules="[v => !!v  , this.duplicatedEAN || $t('erp.lang_barcodeAlreadyTaken')]"
                                autocomplete="off"
                                outlined
                                required
                                @blur="checkEAN"
                                @change="checkEAN"
                                @focus="showTouchKeyboard"
                  >

                    <template slot="append">
                      <div style="margin-top:-5px;">
                        <font-awesome-icon :icon="['fal', 'barcode']" class="linkCursor"
                                           size="2x" @click="GetWareId"/>
                      </div>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex md4 ml-2 mr-2>
                  <v-text-field v-model="form.ware_name"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_itemname')"
                                autocomplete="off"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
                <v-flex md4 ml-2 mr-2>
                  <v-text-field v-model="form.ware_internalNo"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.internal_wareNo')"
                                autocomplete="off"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md4 ml-2 mr-2>
                  <v-text-field v-model="form.ware_buyprice"
                                :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                :label="$t('erp.lang_netPurchasePricePerUnit')"
                                autocomplete="off"
                                outlined
                                type="text"
                                @keypress="decimalNumbers($event, form.ware_buyprice)"
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
                <v-flex md4 ml-2 mr-2>
                  <v-text-field v-model="form.ware_sellprice"
                                :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                :label="$t('erp.lang_sellPrice')"
                                autocomplete="off"
                                outlined
                                type="text"
                                @keypress="decimalNumbers($event, form.ware_sellprice)"
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
                <v-flex md4 ml-2 mr-2>
                  <v-text-field v-model="form.editItemStorageQTY"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_quantityUnit')"
                                autocomplete="off"
                                outlined
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md4 ml-2 mr-2>
                  <v-autocomplete
                      v-model="form.garnishGroup"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :items="garnishGroups"
                      :label="$t('erp.lang_garnishGroup')"
                      :readonly="false"
                      append-icon="add"
                      deletable-chips
                      item-text="name"
                      item-value="uuid"
                      outlined
                      @focus="showTouchKeyboard" @click:append.stop="showGarnishGroup"
                  >

                    <template v-slot:prepend-inner>
                      <v-icon>arrow_drop_down</v-icon>
                    </template>

                    <template v-slot:append>
                      <v-menu
                          id="menu_create"
                          v-model="showGarnishCreate"
                          :close-on-content-click="false"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :nudge-width="200"
                          max-width="304"
                          offset-y
                          transition="slide-x-transition"
                          z-index="10"
                          @focus="showTouchKeyboard"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn id="icon-wiregroup-add" color="primary"
                                 fab
                                 small @click="showGarnishGroup"
                                 v-on="on">
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <v-card outlined>
                          <v-row align="center"
                                 class="dropdown-menu-header"
                                 justify="center">
                            <div class="dropdown-menu-header-inner bg-primary"
                            >
                              <div class="menu-header-image dd-header-bg-1"></div>
                              <div class="menu-header-content text-center pa-3 ma-0"
                              >
                                <div class="text-center">
                                  <h5 class="menu-header-title ">{{ $t('erp.lang_createGarnishGroup') }}</h5>
                                  <span class="mx-auto text-center">
                                              {{ $t('erp.lang_hereYouCanAddNewGarnishGroup') }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </v-row>
                          <div class="scroll-area-xl pa-2">
                            <v-form ref="garnishGroupFrom"
                                    class="ma-0 pa-0"
                                    lazy-validation
                                    onsubmit="return false">

                              <v-row justify-md="center">
                                <v-col cols="12">
                                  <v-text-field v-model="newGarnishGroup.name"
                                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                :label="$t('erp.lang_garnishGroupName')"
                                                :rules="[v=>!!v && v.length>0 || $t('generic.lang_requiredField')]"
                                                autocomplete="off"
                                                outlined
                                                required
                                                @focus="showTouchKeyboard"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <fontawesome-picker v-model="newGarnishGroup.icon"
                                                      :fieldLabel="'Icon'"/>
                                </v-col>
                                <v-col cols="12">
                                  <div class="widget-content text-left pa-0">
                                                                        <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                                    <swatches
                                        v-model="newGarnishGroup.color" inline
                                        background-color="transparent"
                                    ></swatches>
                                  </div>
                                </v-col>
                              </v-row>

                              <v-divider class="pa-0 ma-0"/>
                              <div class="text-center">
                                <button class="btn btn-danger ma-2"
                                        @click="showGarnishCreate = false">
                                  {{ $t('generic.lang_cancel') }}
                                </button>

                                <v-btn :disabled="newGarnishGroup.name===''||newGarnishGroup.name===null"
                                       :loading="loadingCreatGarnishGroup"
                                       color="primary"
                                       @click="createGarnishGroup">
                                  {{ $t('generic.lang_create') }}
                                </v-btn>
                              </div>
                            </v-form>
                          </div>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex md4 ml-2 mr-2>

                  <strong>{{ $t('erp.mealType') }}</strong><br>
                  <v-radio-group v-if="form.gastroFoodType || (!id || parseInt(id) < 1)" v-model="form.gastroFoodType"
                                 row>
                    <v-radio
                        :key="0"
                        :value="1"
                    >
                      <template slot="label">
                        <font-awesome-icon :icon="['fal', 'cocktail']" size="3x"/>
                      </template>
                    </v-radio>

                    <v-radio
                        :key="1"
                        :value="2"
                    >
                      <template slot="label">
                        <font-awesome-icon :icon="['fal', 'meat']" size="3x"/>
                      </template>
                    </v-radio>

                    <v-radio
                        :key="2"
                        :label="`Radio`"
                        :value="3"
                    >
                      <template slot="label">
                        <font-awesome-icon :icon="['fal', 'gifts']" size="3x"/>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex md4 ml-2 mr-2>
                  <v-switch
                      v-if="this.$store.getters['permissions/checkModule'](2)"
                      v-model="form.isDailyGoods"
                      :false-value="0"
                      :label="$t('erp.lang_dishOfTheDayControl')"
                      :loading="loadingIsDailyGoods"
                      :true-value="1"
                      hide-details
                      @change="updateIsDailyGoodsStatus"
                  >
                  </v-switch>
                  <v-checkbox hide-details v-model="form.hasMealSizes" :true-value="1" :false-value="0" :label="$t('erp.lang_garnishHasSizes')">

                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="isTranslationEnabled" key="translation">
            <b-tabs>

              <!-- germany -->
              <b-tab active>
                <template #title>
                  <v-btn class="ma-0" text>
                    <country-flag country="DE"/>
                  </v-btn>
                </template>

                <v-row align="center" justify="center" no-gutters>
                  <v-col cols="12" sm="8">
                    <v-text-field v-model="form.ware_name_de"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_posItemName')"
                                  autocomplete="off"
                                  dense outlined @focus="showTouchKeyboard"></v-text-field>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- english -->
              <b-tab>
                <template #title>
                  <v-btn class="ma-0" text>
                    <country-flag country="GB"/>
                  </v-btn>
                </template>

                <v-row align="center" justify="center" no-gutters>
                  <v-col cols="12" sm="8">
                    <v-text-field v-model="form.ware_name_en"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_posItemName')"
                                  autocomplete="off"
                                  dense outlined @focus="showTouchKeyboard"></v-text-field>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- french -->
              <b-tab>
                <template #title>
                  <v-btn class="ma-0" text>
                    <country-flag country="FR"/>
                  </v-btn>
                </template>

                <v-row align="center" justify="center" no-gutters>
                  <v-col cols="12" sm="8">
                    <v-text-field v-model="form.ware_name_fr"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_posItemName')"
                                  autocomplete="off"
                                  dense outlined @focus="showTouchKeyboard"></v-text-field>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- arabic -->
              <b-tab>
                <template #title>
                  <v-btn class="ma-0" text>
                    <country-flag country="SA"/>
                  </v-btn>
                </template>

                <v-row align="center" justify="center" no-gutters>
                  <v-col cols="12" sm="8">
                    <v-text-field v-model="form.ware_name_ar"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_posItemName')"
                                  autocomplete="off"
                                  dense outlined @focus="showTouchKeyboard"></v-text-field>
                  </v-col>
                </v-row>
              </b-tab>
            </b-tabs>
          </v-tab-item>
          <v-tab-item v-if="this.$store.getters['permissions/checkModule'](24)" key="pricesize">
            <v-container fluid>
              <v-layout>
                <v-flex md12 ml-2 mr-2>

                  <b-table ref="priceSizeTable" :fields="mealSizesFields" :items="mealSizes" hover
                           responsive
                           striped :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
                    <template v-slot:cell(totalprice)="data">
                      <v-text-field v-model="data.item.totalprice"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    autocomplete="off"
                                    outlined
                                    @focus="showTouchKeyboard"
                      ></v-text-field>
                    </template>
                  </b-table>

                </v-flex>
              </v-layout>

            </v-container>
          </v-tab-item>
          <v-tab-item key="picture">
            <v-container fluid>
              <v-layout>
                <v-flex md12 ml-2 mr-2>
                  <image-cropper ref="imageCropper" v-model="form.productImage"/>
                </v-flex>
              </v-layout>

            </v-container>
          </v-tab-item>
          <v-tab-item key="wareattributes">
            <v-container fluid>
              <v-layout row>
                <v-flex md4 ml-2 mr-2 xs12>
                  <v-text-field v-model="form.biozertnr"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_warecreate_biozertno')"
                                autocomplete="off"
                                outlined
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
                <v-flex md4 ml-2 mr-2 xs8>
                  <v-text-field v-model="form.BioText"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_warecreate_biotext')"
                                autocomplete="off"
                                outlined
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-flex>
                <v-flex md3 ml-2 mr-2 xs3>
                  <v-checkbox
                      v-model="form.bio_yes_no"
                      :label="$t('erp.lang_warecreate_bioware')"
                      autocomplete="off"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <br>

              <v-layout>
                <v-flex>
                  <label style="font-size:120%;">{{ $t("erp.lang_menuAndGarnishExtraType") }}</label>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex key="mg-1" md4 xs12>
                  <v-checkbox :hide-details="true" value="1" style="margin-top: 0px !important" v-model="form.mealSpecialType">
                    <template v-slot:label>
                      <div class="d-flex">
                        <v-img height="30" width="30" src="@/assets/images/menu-type/vegetables_12087540.svg" contain class="mr-2"></v-img>
                        <span>{{ $t('erp.lang_vegetarian') }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>

                <v-flex key="mg-2" md4 xs12>
                  <v-checkbox :hide-details="true" value="2" style="margin-top: 0px !important" v-model="form.mealSpecialType">
                    <template v-slot:label>
                      <div class="d-flex">
                        <v-img height="30" width="30" src="@/assets/images/menu-type/vegan_5579100.svg" contain class="mr-2"></v-img>
                        <span>{{ $t('erp.lang_vegan') }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>

                <v-flex key="mg-3" md4 xs12>
                  <v-checkbox :hide-details="true" value="3" style="margin-top: 0px !important" v-model="form.mealSpecialType">
                    <template v-slot:label>
                      <div class="d-flex">
                        <v-img height="30" width="30" src="@/assets/images/menu-type/halal_10278615.svg" contain class="mr-2"></v-img>
                        <span>{{ $t('erp.lang_halal') }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>

                <v-flex key="mg-4" md4 xs12>
                  <v-checkbox :hide-details="true" value="4" style="margin-top: 0px !important" v-model="form.mealSpecialType">
                    <template v-slot:label>
                      <div class="d-flex">
                        <v-img height="30" width="30" src="@/assets/images/menu-type/menorah_5443256.svg" contain class="mr-2"></v-img>
                        <span>{{ $t('erp.lang_kosher') }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>

              <br>
              <br>

              <v-layout>
                <v-flex>
                  <label style="font-size:120%;">{{ $t("erp.lang_Zusatzstoffe") }}</label>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex v-for="(additive, index) in additives" :key="'z-' + index" md4 xs12>
                  <v-checkbox
                      v-model="additives[index].selected"
                      :hide-details=true
                      :label="additive.label"
                      autocomplete="off"
                      style="margin-top:0px !important;"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <br>
              <br>

              <v-layout>
                <v-flex>
                  <label style="font-size:120%;">{{ $t("erp.lang_Allergene") }}</label>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex v-for="(allergen, index) in allergene" :key="'a-' + index" md4 xs12>
                  <v-checkbox
                      v-model="allergene[index].selected"
                      :hide-details=true
                      :label="allergen.label"
                      autocomplete="off"
                      style="margin-top:0px !important;"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item
              key="ingredient"
              v-if="this.$store.getters['permissions/checkModule'](24)&&(id || parseInt(id) > 0) "
          >
            <v-card-text class="ma-0 pa-0">
              <IngredientComponent
                  v-bind:ingredients="ingredients"
              ></IngredientComponent>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-container fluid :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
        <v-layout>
          <v-flex class="text-right">
            <v-btn color="error" text @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn v-if="(id || parseInt(id) > 0) && this.$store.getters['permissions/checkPermission']('ware_delete')"
                   :disabled="this.updating"
                   color="error"
                   @click="deleteData">{{
                $t('generic.lang_delete')
              }}
            </v-btn>
            <v-btn v-if="(!id || parseInt(id) > 1)" :disabled="this.updating"
                   :loading="this.updating"
                   color="primary"
                   @click="bulkPriceUpdate">
              <span>{{ $t("erp.lang_override_prices") }}</span>
            </v-btn>


            <v-btn
                :disabled="(!form.ware_ID || form.ware_ID.length < 1) || (!form.ware_name || form.ware_name.length < 1) || this.updating"
                :loading="this.updating"
                color="success"
                @click="updateData">
              <span v-if="(!id || parseInt(id) < 1)">{{ $t('generic.lang_add') }}</span>
              <span v-else>{{ $t('generic.lang_save') }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </div>
</template>

<style>
.v-btn--floating {
  width: 30px !important;
  height: 30px !important;
}

.dataTables_filter {
  display: none;
}

.extra-padding .v-input {
  margin-top: 10px;
}

.extra-padding .v-input input {
  padding-bottom: 12px;
  max-height: 42px;
}

.narrow-list .v-input {
  margin-top: 5px !important;
}

.narrow-list .v-input__slot {
  margin-bottom: 0px !important;
}

.narrow-list label {
  margin-bottom: 0px !important;
}
</style>

<script>
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";
import ImageCropper from "../../common/imagecropper";
import DatePicker from "../../common/datepicker";
import {library} from '@fortawesome/fontawesome-svg-core'
import Swatches from 'vue-swatches';
import CountryFlag from "vue-country-flag";
import FontawesomePicker from "../../common/iconPicker"

import {faBarcode, faCocktail, faGifts, faMeat} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import mixin from "../../../mixins/KeyboardMixIns";
import {createNamespacedHelpers} from "vuex";
import IngredientComponent from "@/components/erp/baseData/edititem/IngredientComponent";

library.add(
    faBarcode,
    faCocktail,
    faMeat,
    faGifts
);

export default {
  components: {
    Datatable,
    ImageCropper,
    DatePicker,
    Swatches,
    FontawesomePicker,
    'font-awesome-icon': FontAwesomeIcon,
    CountryFlag,
    IngredientComponent
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loadingIsDailyGoods: false,

      showGarnishCreate: false,
      garnishGroups: [],
      garnishGroup: '',
      newGarnishGroup: {
        name: null,
        color: null,
        icon: null
      },
      loadingCreatGarnishGroup: false,
      mealSizesFields: [
        {
          key: 'sizevariation',
          label: this.$t("erp.lang_SizeVariation"),
          sortable: true,
          'class': 'text-left'
        },
        {
          key: 'totalprice',
          label: this.$t("erp.lang_newTotalPrice"),
          sortable: false,
          'class': 'text-center'
        },
      ],
      id: this.$route.params.id ? this.$route.params.id : null,
      loading: false,
      loadingCheck: false,
      updating: false,
      duplicatedEAN: true,
      form: {
        ware_ID: null,
        isDailyGoods: 0,
        hasMealSizes: false,
        ware_name: null,
        ware_internalNo: null,
        ware_buyprice: null,
        ware_sellprice: null,
        editItemStorageQTY: null,
        gastroFoodType: null,
        biozertnr: null,
        BioText: null,
        bio_yes_no: null,
        zusaetze: [],
        allergene: [],
        mealSizes: [],
        mealSpecialType: [],
        garnishGroup: null,
        ware_name_de: null,
        ware_name_en: null,
        ware_name_ar: null,
        ware_name_fr: null,
      },
      mealSizes: [],
      ingredients: [],
      additives: [],
      allergene: [],
      tab: 0,
    }
  },
  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    }
  },
  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    },
  },

  methods: {
    updateIsDailyGoodsStatus() {
      this.loadingIsDailyGoods = true;
      this.axios
          .post(ENDPOINTS.ERP.DAILYGOODS.UPDATE.ISDAILYGOODS, {
            editItemID: this.id,
            isDailyGoods: this.form.isDailyGoods,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: res.data.message,
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message:
                    this.$t("generic.lang_errorOccurred") +
                    " : " +
                    (res.data.message || res.data.status),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + ": " + err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loadingIsDailyGoods = false;
          });
    },
    bulkPriceUpdate() {
      this.$swal({
        title: this.$t("erp.lang_override_prices"),
        text: this.$t("erp.lang_override_prices_confirmation"),
        confirmButtonText: this.$t('generic.lang_apply'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;
          this.axios
              .post(ENDPOINTS.ERP.GARNISH.OVERRIDE_PRICE, {
                garnishId: this.id
              })
              .then((res) => {
                if (res.status === 200) {
                  Events.$emit("showSnackbar", {
                    message: this.$t("erp.lang_garnishDeleted"),
                    color: "success",
                  });
                } else {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                  });
                }
              }).catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }).finally(() => {
            this.loading = false;
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    showGarnishGroup() {
      this.showGarnishCreate = true
    },
    getGarnishGroups() {
      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.garnishGroups = res.data.groups;
        }
      }).catch(err => {

      })
    },
    checkEAN() {
      this.duplicatedEAN = true;

      if (this.form.ware_ID.length === 0)
        return;

      this.loadingCheck = true;

      //PREPARE POST PAYLOAD
      let payload = {
        barcode: this.form.ware_ID
      };

      if (this.id !== null)
        payload.itemId = this.id;

      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, payload).then((res) => {
        this.duplicatedEAN = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheck = false;
      })
    },
    createGarnishGroup() {
      if (!this.$refs.garnishGroupFrom.validate()) return;
      let form = {};
      form.name = this.newGarnishGroup.name;
      form.color = this.newGarnishGroup.color;
      form.icon = this.newGarnishGroup.icon;
      this.loadingCreatGarnishGroup = true;

      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.CREATE, form).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_garnishGroupCreated'),
            color: "success"
          });

          if (this.garnishGroups === null)
            this.garnishGroups = []

          this.garnishGroups.push({
            uuid: res.data.group.uuid,
            name: res.data.group.name,
            icon: res.data.group.icon,
            color: res.data.group.color,
          });

          if (this.garnishGroups && this.garnishGroups.length > 0) {

            this.form.garnishGroup = res.data.group.uuid
          }
          this.loadingCreatGarnishGroup = false;
          this.$refs.garnishGroupFrom.reset();


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: res.data.msg || res.data.status
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCreatGarnishGroup = false;
      });
    },

    GetWareId() {
      let self = this;

      self.axios.post(ENDPOINTS.ERP.INVENTORYITEM.EAN.CREATE).then((res) => {
        if (res.status === 200) {
          self.form.ware_ID = res.data && res.data != null && res.data.length > 0 ? res.data[0] : self.form.ware_ID;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    updateData: function () {
      let self = this;
      let url = ENDPOINTS.ERP.GARNISH.CREATE

      let formData = new FormData();

      if (self.id && self.id > 0) {
        url = ENDPOINTS.ERP.GARNISH.UPDATE
        formData.append("editItemID", parseInt(self.id))
      } else {
        self.form.editItemID = null;
      }

      if (this.$refs.hasOwnProperty("imageCropper"))
        this.$refs.imageCropper.cropImage();
      formData.append("garnishGroup", self.form.garnishGroup)
      formData.append("ware_ID", self.form.ware_ID)
      formData.append("ware_name", self.form.ware_name)
      formData.append("ware_internalNo", self.form.ware_internalNo)
      formData.append("ware_buypriceNet", self.form.ware_buyprice)
      formData.append("ware_sellpriceNet", self.form.ware_sellprice)
      formData.append("editItemStorageQTY", self.form.editItemStorageQTY)
      formData.append("gastroFoodType", self.form.gastroFoodType)
      formData.append("biozertnr", self.form.biozertnr)
      formData.append("BioText", self.form.BioText)
      formData.append("bio_yes_no", self.form.bio_yes_no)
      formData.append("productImageUpload", self.form.productImage)
      formData.append("ware_name_de", self.form.ware_name_de)
      formData.append("ware_name_en", self.form.ware_name_en)
      formData.append("ware_name_ar", self.form.ware_name_ar)
      formData.append("ware_name_fr", self.form.ware_name_fr)
      formData.append("hasMealSizes", self.form.hasMealSizes)
      formData.append("Ingredients", JSON.stringify(self.ingredients))

      if (self.form.mealSpecialType && self.form.mealSpecialType.length > 0) {
        for (let i = 0; i < self.form.mealSpecialType.length; i++) {
          formData.append("mealSpecialType[]", self.form.mealSpecialType[i])
        }
      }

      let selected = self.additives.filter(z => z.selected == true)
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          formData.append("zusaetze[]", selected[i].key)
        }
      }

      selected = self.allergene.filter(z => z.selected == true)
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          formData.append("allergene[]", selected[i].key)
        }
      }

      if (self.mealSizes && self.mealSizes.length > 0) {
        for (let i = 0; i < self.mealSizes.length; i++) {
          let size = self.mealSizes[i];
          formData.append("mealVariationSizePrice_" + size.key, size.totalprice)
        }
      }

      this.updating = true;
      this.axios.post(url, formData).then((res) => {
        if (res.data.success || (res.data.garnishID && parseInt(res.data.garnishID) > 0)) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          self.resetData();
        } else if (res.data.status === 'CHECK ERROR' || res.data.status === 'duplicateItem') {

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_eanCheckFailed'),
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => this.updating = false);
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteGarnish'),
        text: this.$t('erp.lang_deleteGarnishText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.GARNISH.DELETE, {
            deleteItemID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_garnishDeleted'),
                color: "success"
              });

              self.resetData();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetData() {
      this.$router.push({name: "erp.baseData.garnish"});
    },
    loadData() {
      let self = this;

      if (self.id && parseInt(self.id) > 0) {
        return;
      }


      self.axios.get(ENDPOINTS.ERP.INVENTORYITEM.GETDATA).then((res) => {
        self.allergene = [];
        self.additives = [];
        if (res.status === 200) {
          let allergensArrChecker = res.data.allergensArrChecker && res.data.allergensArrChecker != null && res.data.allergensArrChecker.length > 0 ? res.data.allergensArrChecker : [];
          let additivesArrChecker = res.data.additivesArrChecker && res.data.additivesArrChecker != null && res.data.additivesArrChecker.length > 0 ? res.data.additivesArrChecker : [];

          if (res.data.mealSizes && res.data.mealSizes.length > 0) {
            for (let i = 0; i < res.data.mealSizes.length; i++) {
              self.mealSizes.push({
                key: res.data.mealSizes[i][0],
                sizevariation: res.data.mealSizes[i][1],
                totalprice: res.data.mealSizes[i][2]
              })
            }
          }
          if (res.data.allergensArr && res.data.allergensArr.length > 0) {
            let total = res.data.allergensArr.length;
            for (let i = 0; i < total; i++) {
              let allergen = res.data.allergensArr[i]
              let index = allergensArrChecker.findIndex(a => a == allergen[0]);
              let selected = index >= 0 ? true : false;
              allergen = {"key": allergen[0], "label": allergen[1], "selected": selected};
              self.allergene.push(allergen)
            }
          }

          if (res.data.additivesArr && res.data.additivesArr.length > 0) {
            let total = res.data.additivesArr.length;
            for (let i = 0; i < total; i++) {
              let additive = res.data.additivesArr[i];
              let index = additivesArrChecker.findIndex(a => a == additive[0]);
              let selected = index >= 0 ? true : false;
              additive = {"key": additive[0], "label": additive[1], "selected": selected};
              self.additives.push(additive)
            }
          }

        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    entryEdit() {
      let self = this;

      if (!(self.id && self.id > 0)) {
        return;
      }

      self.axios.post(ENDPOINTS.ERP.GARNISH.GET, {
        editItemID: parseInt(self.id)
      }).then((res) => {
        if (res.status === 200) {
          let allergensArrChecker = res.data.allergensArrChecker && res.data.allergensArrChecker != null && res.data.allergensArrChecker.length > 0 ? res.data.allergensArrChecker : [];
          let additivesArrChecker = res.data.additivesArrChecker && res.data.additivesArrChecker != null && res.data.additivesArrChecker.length > 0 ? res.data.additivesArrChecker : [];

          self.form.garnishGroup = res.data.formfillData.garnishGroup
          self.form.ware_ID = res.data.formfillData.textFields.ware_ID;
          self.form.ware_name = res.data.formfillData.textFields.ware_name;
          if (res.data.formfillData.textFields.name_translations) {
            self.form.ware_name_de = res.data.formfillData.textFields.name_translations.ware_name_de;
            self.form.ware_name_en = res.data.formfillData.textFields.name_translations.ware_name_en;
            self.form.ware_name_ar = res.data.formfillData.textFields.name_translations.ware_name_ar;
            self.form.ware_name_fr = res.data.formfillData.textFields.name_translations.ware_name_fr;
          }
          self.form.ware_internalNo = res.data.formfillData.textFields.ware_internalNo;
          self.form.ware_buyprice = res.data.formfillData.textFields.ware_buypriceNet;
          self.form.ware_sellprice = res.data.formfillData.textFields.ware_sellpriceNet;
          self.form.gastroFoodType = res.data.formfillData.gastroFoodType;
          self.form.editItemStorageQTY = res.data.formfillData.textFields.editItemStorageQTY;
          self.form.biozertnr = res.data.formfillData.textFields.biozertnr;
          self.form.BioText = res.data.formfillData.textFields.BioText;
          self.form.bio_yes_no = res.data.formfillData.textFields.bio_yes_no;
          self.form.productImage = res.data.imageData[1];
          self.form.zusaetze = [];
          self.form.allergene = [];
          self.mealSizes = [];
          self.form.mealSpecialType = res.data.formfillData.checkBoxData.mealSpecialType;

          this.form.isDailyGoods = res.data.formfillData.checkBoxData.isDailyGoods;
          this.form.hasMealSizes = res.data.formfillData.checkBoxData.hasMealSizes;

          this.$emit('input', self.form.ware_name)
          if (res.data.mealSizes && res.data.mealSizes.length > 0) {
            for (let i = 0; i < res.data.mealSizes.length; i++) {
              self.mealSizes.push({
                key: res.data.mealSizes[i][0],
                sizevariation: res.data.mealSizes[i][1],
                totalprice: res.data.mealSizes[i][2]
              })
            }
          }
          self.allergene = [];
          if (res.data.allergensArr && res.data.allergensArr != null && res.data.allergensArr.length > 0) {
            for (let i = 0; i < res.data.allergensArr.length; i++) {
              let allergen = res.data.allergensArr[i]
              let index = allergensArrChecker.findIndex(a => a == allergen[0]);
              let selected = index >= 0 ? true : false;
              allergen = {"key": allergen[0], "label": allergen[1], "selected": selected};
              self.allergene.push(allergen)
            }
          }
          self.additives = [];
          if (res.data.additivesArr && res.data.additivesArr != null && res.data.additivesArr.length > 0) {
            for (let i = 0; i < res.data.additivesArr.length; i++) {
              let additive = res.data.additivesArr[i];
              let index = additivesArrChecker.findIndex(a => a == additive[0]);
              let selected = index >= 0 ? true : false;
              additive = {"key": additive[0], "label": additive[1], "selected": selected};
              self.additives.push(additive)
            }
          }
          this.ingredients = res.data.ingredients;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    },
    decimalNumbers(evt, val) {
      if(isNaN(evt.key) && evt.key !== '.' && evt.key !== ',' && evt.key !== '-' && evt.key !== 'Backspace') {
        evt.preventDefault();
      } else {
        if ((evt.key == '-' && val.match(/-/g) || []).length > 1 || (evt.key == '.' && val.indexOf(".") > -1)) {
          evt.preventDefault();
        }
      }
    },
  },
  mounted() {
    //console.log("AddUpdateGarnish");

    this.getGarnishGroups();
    this.entryEdit();
    this.loadData();
  }
  ,
}
</script>
